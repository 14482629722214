import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export default (id, autoplay, speed, total, is_hero) => ({
  id: id,
  autoplay: autoplay,
  speed: speed,
  total: total,
  is_hero: is_hero,
  swiper: null,

  init() {
    const speed = this.speed * 1000 || 5000;

    const params = {
      loop: true,
      slidesPerView: 1,
    };

    if (this.autoplay) {
      params.autoplay = {
        delay: speed,
        disableOnInteraction: false,
      };

      if (!this.is_hero) {
        params.autoplay.delay = 0;
      }
    }

    if (!this.is_hero) {
      params.freeMode = true;
      params.speed = speed;
      params.spaceBetween = 0;
      params.breakpoints = {
        640: {
          slidesPerView: 2,
        },
        782: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      };
    } else {
      params.effect = "fade";
      params.fadeEffect = { crossFade: true };
      params.navigation = {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      };
      params.pagination = {
        el: ".swiper-pagination",
        clickable: true,
      };
      params.speed = 1000;
    }

    this.swiper = new Swiper(id, params);
  },

  prev() {
    // this.flkty.previous();
  },

  next() {
    // this.flkty.next();
  },
});
