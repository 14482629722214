import "../css/missio.css";

import { intersectEffect } from "./components/intersect-effect";

import Alpine from "alpinejs";

import modal from "./components/modal";
import carousel from "./components/carousel";
import slideshow from "./components/slideshow";
import iconlist from "./components/iconlist";
import videoThumbnail from "./components/video-thumbnail";

window.Alpine = Alpine;
window.MeladraIntersectEffect = intersectEffect;

Alpine.data("modal", modal);
Alpine.data("carousel", carousel);
Alpine.data("slideshow", slideshow);
Alpine.data("iconlist", iconlist);
Alpine.data("videoThumbnail", videoThumbnail);

Alpine.store("modal", modal);
Alpine.store("mobileMenu", { open: false });

Alpine.start();

(function () {
  document
    .querySelectorAll(
      "\
      h2:not(.no-fade),\
      h3:not(.no-fade),\
      h4:not(.no-fade),\
      .wp-block-button:not(.no-fade),\
      .wp-block-image:not(.no-fade, .is-style-full),\
      .wp-block-post-featured-image:not(.no-fade, .is-style-full)",
    )
    .forEach(($el) => $el.classList.add("fade"));

  intersectEffect(".fade", "fade-init", 0.25);

  // AlpineJS Modal
  document.querySelectorAll(`[href^="#modal-"]`).forEach(($el) => {
    $el.addEventListener("click", (ev) => {
      ev.preventDefault();

      Alpine.store("modal").open(
        $el.getAttribute("href").replace("#modal-", ""),
      );
    });
  });
})();
