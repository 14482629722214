export default {
  active: null,

  init() {},

  isOpen(modal) {
    return this.active === modal;
  },

  open(modal) {
    this.overflowEls.forEach(($el) => $el.classList.add("overflow-hidden"));

    this.active = modal;
  },

  close() {
    if (!this.active) {
      return;
    }

    this.overflowEls.forEach(($el) => $el.classList.remove("overflow-hidden"));

    document
      .getElementById(this.active)
      .querySelectorAll("iframe")
      .forEach(($iframe) => {
        let src = $iframe.src;
        $iframe.src = "";
        $iframe.src = src;
      });

    this.active = null;
  },

  get overflowEls() {
    return document.querySelectorAll("html, body");
  },
};
