export default () => ({
  play: false,

  init() {
    this.$watch("play", (value) => {
      if (value) {
        this.loadVideo();
      }
    });
  },

  toggleVideo() {
    this.play = !this.play;
  },

  loadVideo() {
    if (this.isYouTube()) {
      const iframe = this.$refs.videoIframe;
      const youtubeUrl = iframe.dataset.youtubeUrl;
      iframe.src = `${youtubeUrl}?autoplay=1&playsinline=1`;
    } else if (this.isVideo()) {
      const videoElement = this.$refs.videoElement;
      videoElement.play();
    }
  },

  isYouTube() {
    return this.$refs.videoIframe !== undefined;
  },

  isVideo() {
    return this.$refs.videoElement !== undefined;
  },
});
