export default (columns) => ({
  init() {
    const itemCount = this.$el.querySelectorAll(
      ".wp-block-acf-icon-list-item",
    ).length;

    const rows = Math.round(itemCount / columns);

    this.$el.querySelector(".icon-list-inner").style.cssText =
      `grid-template-rows: repeat(${rows}, 1fr);`;
  },
});
