export const intersectEffect = (
  selector,
  initClass,
  threshhold = 0.5,
  delay = 150,
  callback,
) => {
  document.querySelectorAll(selector).forEach((el) => {
    let observer = new IntersectionObserver(
      (entries) => {
        if (el.classList.contains(initClass)) {
          observer.disconnect();
          return;
        }

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTimeout(() => {
              el.classList.add(initClass);
            }, el.dataset.effectDelay || delay);
          }
        });
      },
      { threshhold },
    );

    observer.observe(el);
  });

  if (callback) {
    callback(selector, initClass, threshhold, delay);
  }
};
