import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export default (id, autoplay, speed, total) => ({
  id: id,
  autoplay: autoplay,
  speed: speed,
  total: total,
  swiper: null,

  init() {
    const speed = this.speed * 1000 || 3000;
    let autoplay = {};

    if (this.autoplay) {
      autoplay = {
        delay: 0,
        disableOnInteraction: false,
      };
    } else {
      autoplay = false;
    }

    this.swiper = new Swiper(id, {
      loop: true,
      spaceBetween: 0,
      effect: "fade",
      autoplay: autoplay,
      slidesPerView: 1,
      speed: speed,
    });
  },

  prev() {
    // this.flkty.previous();
  },

  next() {
    // this.flkty.next();
  },
});
